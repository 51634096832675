import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import MDEditor from '@uiw/react-md-editor';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';
import Dropdown from 'react-bootstrap/Dropdown';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import dayjs from 'dayjs';
import { removeSitrepSection, updateSitrepSection } from 'actions/dgptActions';
import { SharedIcon } from '../SharedIcon/SharedIcon';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';

const SitrepSection = ({
  sitrep,
  section,
  showMostRecent = true,
  timestampSelectedCallback,
}) => {
  const reduxDispatch = useDispatch();

  const [version, setVersion] = useState(section.section_versions[0]);
  const [editing, setEditing] = useState(false);
  const [text, setText] = useState('');
  const [priorText, setPriorText] = useState('');

  useEffect(() => {
    if (
      !!section &&
      !!section.section_versions &&
      !!section.section_versions[0]
    ) {
      setVersion(section.section_versions[0]);
      setText(section.section_versions[0].sitrep_section_text);
      setPriorText(section.section_versions[0].sitrep_section_text);
      if (section.section_versions.length > 1) {
        setPriorText(section.section_versions[1].sitrep_section_text);
      }
    } else {
      setVersion();
      setText();
    }
  }, [section]);

  useEffect(() => {
    setText(version.sitrep_section_text);
    setPriorText(version.sitrep_section_text);
    if (section.section_versions.length > 1) {
      const idx = section.section_versions.findIndex(
        (v) => v.version_id === version.version_id
      );
      if (!!section.section_versions[idx + 1]) {
        setPriorText(section.section_versions[idx + 1].sitrep_section_text);
      }
    }
  }, [version]);

  function editClicked() {
    setEditing(true);
  }

  function saveClicked() {
    setEditing(false);
    reduxDispatch(
      updateSitrepSection({
        sitrepId: sitrep.id,
        sectionId: section.section_id,
        sectionText: text,
        sectionTitle: version.sitrep_section_title,
      })
    );
  }

  function cancelClicked() {
    setEditing(false);
    setText(version.sitrep_section_text);
  }

  function removeClicked() {
    if (window.confirm('Remove this Section from the SITREP?')) {
      reduxDispatch(
        removeSitrepSection({
          sitrepId: sitrep.id,
          sectionId: section.id,
        })
      );
    }
  }

  function MarkdownComponent({ markdown }) {
    return (
      <ReactMarkdown
        children={markdown}
        rehypePlugins={[rehypeRaw, rehypeSanitize]}
      />
    );
  }

  const customSchema = {
    ...defaultSchema,
    attributes: {
      ...defaultSchema.attributes,
      span: [...(defaultSchema.attributes.span || []), 'style'],
    },
  };

  // Format text for markdown
  let formattedText =
    (!!text &&
      text
        .replaceAll(`{{`, `<span style="color: var(--warning-400);">`)
        .replaceAll(`}}`, `</span>`)) ||
    '';
  formattedText = formattedText.replaceAll('```json', '').replaceAll('```', '');
  if (
    formattedText[0] === `"` &&
    formattedText[formattedText.length - 1] === `"`
  ) {
    formattedText = formattedText.slice(1, -1);
  }
  formattedText = formattedText.replace('markdown', '');
  formattedText = formattedText.replaceAll(/\\n/g, '\n');
  formattedText = formattedText.replaceAll(/【[^】]*】/g, '\n');

  return (
    <div className="SitrepSection">
      <div key={'dpgt-message-'}>
        <div key={'EventAIAnalysis-item'}>
          <div className="paragraph-header">
            <h4>{version.sitrep_section_title}</h4>
            <span>
              <Dropdown className="flex-shrink-0 ms-auto">
                <Dropdown.Toggle className="button--icon border-0">
                  <SharedIcon iconName="more_vert" />
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  {editing ? (
                    <>
                      <Dropdown.Item as="button" onClick={() => saveClicked()}>
                        Save
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() => cancelClicked()}
                      >
                        Cancel
                      </Dropdown.Item>
                    </>
                  ) : (
                    <>
                      {/*<Dropdown.Item as="button" onClick={() => editClicked()}>*/}
                      {/*  Edit*/}
                      {/*</Dropdown.Item>*/}
                      <Dropdown.Item
                        as="button"
                        onClick={() => removeClicked()}
                      >
                        Remove
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </span>
          </div>
          <div className="p-3">
            <div className="date-section">
              {!editing &&
                (section.section_versions.length === 1 ? (
                  <>
                    <span className="material-symbols-outlined me-2 fs-5">
                      update
                    </span>
                    {dayjs(section.section_versions[0].timestamp).format(
                      'hh:mm, ddd MMMM D, YYYY'
                    )}
                  </>
                ) : (
                  <>
                    <span className="material-symbols-outlined fs-5">
                      update
                    </span>
                    <StylishNewSelect
                      options={section.section_versions.map((v) => {
                        return {
                          label: dayjs(v.timestamp).format(
                            'hh:mm, ddd MMMM D, YYYY'
                          ),
                          value: v,
                        };
                      })}
                      defaultValue={version}
                      value={dayjs(version.timestamp).format(
                        'hh:mm, ddd MMMM D, YYYY'
                      )}
                      onChange={(e) => {
                        setVersion(e.value);
                      }}
                      placeholder={dayjs(version.timestamp).format(
                        'hh:mm, ddd MMMM D, YYYY'
                      )}
                      isClearable={true}
                      isSearchable={true}
                      isMulti={false}
                      isDisabled={false}
                    />
                  </>
                ))}
            </div>
            <div className="text-section">
              {(!!text && (
                <>
                  {(!editing && (
                    <>
                      <ReactMarkdown
                        children={formattedText}
                        rehypePlugins={[
                          [rehypeRaw, { passThrough: ['element'] }],
                          [rehypeSanitize, customSchema],
                        ]}
                      />
                    </>
                  )) || (
                    <div className="SitrepSection-editor-wrap">
                      <MDEditor value={text} onChange={setText} height="100%" />
                    </div>
                  )}
                </>
              )) || (
                <div className="d-flex justify-content-center">
                  <i className="fa fa-spinner fa-pulse fs-5 "></i>
                </div>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-end p-3 pt-0">
            {editing ? (
              <StylishNewButton
                className="button button--secondary me-3"
                onClick={() => cancelClicked()}
              >
                Cancel
              </StylishNewButton>
            ) : null}
            {editing ? (
              <StylishNewButton
                className="button button--primary"
                onClick={() => saveClicked()}
              >
                Save
              </StylishNewButton>
            ) : (
              <StylishNewButton
                className="button button--secondary"
                onClick={() => editClicked()}
              >
                Edit
              </StylishNewButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SitrepSection;
