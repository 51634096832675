
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { SharedIcon } from '../SharedIcon/SharedIcon';
import EntityList from './EntityList';
import { useToggle } from 'react-use';
import { Card } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import {
  WorkAssignment,
  useWorkAssignments,
} from './hooks/workAssignmentHooks';
import WorkAssignmentAddDrawer from './WorkAssignmentAddDrawer';
import WorkAssignmentEditDrawer from './WorkAssignmentEditDrawer';
import { useDispatch, useSelector } from 'react-redux';

import { fetchRostersForGroups } from 'actions/roleActions';
import {
  getAllBranch,
  getAllDivision,
} from 'actions/IAPForm204Actions';

import {
  MdEdit,
} from 'react-icons/md';

interface CollapsibleWorkAssignmentsListProps {
  onMap?: boolean;
  flyTo?: any;
}

const CollapsibleWorkAssignmentsList: React.FC<CollapsibleWorkAssignmentsListProps> = ({ onMap = false, flyTo }) => {
  const reduxDispatch = useDispatch()
  const [expanded, toggleExpanded] = useToggle(onMap);
  const [editOpen, toggleEditDrawer] = useToggle(false);
  const [addOpen, toggleAddDrawer] = useToggle(false);
  const [
    selectedEntity,
    setSelectedEntity,
  ] = useState<WorkAssignment | null>(null);

  const { data: workAssignments } = useWorkAssignments();

  const selectElement = (element: WorkAssignment) => {
    setSelectedEntity(element);
    toggleEditDrawer();
  };

  const branches = useSelector((state: any) => state.app.allBranches);
  const reduxCurrentlySelectedGroup = useSelector((state: any) => {
    return state.app.currentlySelectedGroup;
  });

  useEffect(() => {
    if (
      !!reduxCurrentlySelectedGroup &&
      !!reduxCurrentlySelectedGroup.group_guid
    ) {
      reduxDispatch(getAllBranch());
      reduxDispatch(
        fetchRostersForGroups([reduxCurrentlySelectedGroup.group_guid])
      );
    }
  }, [reduxCurrentlySelectedGroup]);




  return (
    <>
      <EntityList
        entities={workAssignments}
        expanded={expanded}
        toggleExpanded={toggleExpanded}
        header={
          <div className="d-flex align-items-center justify-content-between flex-grow-1">
            <span>Work Assignments</span>
            <StylishNewButton
              className={classNames('button', {
                'button--primary': expanded,
                'button--secondary': !expanded,
              })}
              onClick={toggleAddDrawer}
            >
              <SharedIcon iconName="add" classes="me-2" />
              Add
            </StylishNewButton>
          </div>
        }
        renderEntity={(element) => (
          <Card
            key={element.id}
            className="bg-neutral-800 my-1 rounded-1 flex-row align-items-center justify-content-between"
            style={{
              padding: '0.625rem 1rem 0.625rem 1rem',
            }}
          >
            <span>{element.work_assignment}</span>
            <span style={{float:"right"}}>
            {!!onMap && (
              <StylishNewButton
                className="button button--link"
                onClick={() => flyTo(element)}
              >
                View
              </StylishNewButton>
            )}
            <StylishNewButton
              className="button button--link"
              onClick={() => selectElement(element)}
            >
              <MdEdit />
            </StylishNewButton>
            </span>
          </Card>
        )}
      ></EntityList>
      {addOpen ? <WorkAssignmentAddDrawer toggle={toggleAddDrawer} /> : null}
      {editOpen && selectedEntity ? (
        <WorkAssignmentEditDrawer
          toggle={toggleEditDrawer}
          selectedEntity={selectedEntity}
        />
      ) : null}
    </>
  );
};

export default CollapsibleWorkAssignmentsList;
