import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import Network from '../PREPAREsrc/service/Network';
import API from '../PREPAREsrc/service/api';
import { changeSelectedWorkSpaceName } from '../PREPAREsrc/store/workspaces/workspaces.action';
import { Modal } from 'react-bootstrap';
import StylishNewSelect from '../components/DesignSystems/New/StylishNewSelect';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const SelectExerciseModal = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch();
  const [
    selectedExercise,
    setSelectedExercise,
  ] = useState<ExerciseWorkspace | null>(null);
  const exercises = useSelector((state: any) => {
    return state.prepare.workspace.allWorkspace as ExerciseWorkspace[];
  });
  const user = useSelector((state: any) => {
    return state.app.user as User;
  });

  const handleSelect = async () => {
    if (!selectedExercise) return;
    try {
      await Network.post(API.updateUserCurrentWorkspace, {
        workspaceId: selectedExercise.id,
        userId: user.epic_user_id,
      });

      dispatch(
        changeSelectedWorkSpaceName(
          selectedExercise.displayName,
          selectedExercise.ownertype
        )
      );
      onClose();
    } catch (e) {
      toast.error(
        'Error selecting workspace. Please try again or reload the page.'
      );
    }
  };
  return (
    <Modal show={true}>
      <Modal.Header>
        <Modal.Title>Exercise Workspace Selection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {exercises.length > 0 ? (
          <>
            <p>Please select a workspace to work in. </p>
            <StylishNewSelect
              options={exercises
                .filter(exer => exer.teams_status !== 'inprogress') 
                .map((exer) => ({
                label: exer.displayName,
                value: exer.id,
              }))}
              onChange={(e) => {
                setSelectedExercise(
                  exercises.find((exer) => exer.id === e.value) || null
                );
              }}
              isClearable={false}
              isSearchable={false}
              isMulti={false}
            />
          </>
        ) : (
          <p>
            There are no workspaces in this organization. Please create one.
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        {exercises.length === 0 ? (
          <Button
            className="button button--primary"
            as={Link as any}
            to="/groups/exercises"
          >
            Create a New workspace
          </Button>
        ) : (
          <>
            <Button
              className="button button--secondary"
              as={Link as any}
              to="/map"
            >
              Go to Map
            </Button>
            <Button
              className="button button--primary"
              onClick={handleSelect}
              disabled={selectedExercise === null}
            >
              Select Workspace
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default SelectExerciseModal;