import { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { MSELCreate } from '../Prepare/MSEL/MSELCreate';
import CreateParticipant from '../Prepare/Participant/CreateParticipant';
import TrainingObjectiveCreateDialog from '../Prepare/TrainingObjective/TrainingObjectiveCreateDialog';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { toast } from 'react-toastify';
import Network from '../../../service/Network';
import API from '../../../service/api';
import { useNavigate } from 'react-router-dom';
import { selectedEventId } from '../../../store/PlanDashboard/PlanDashboard.action';
import EditMSELTimeline from '../Train/MSELTimeline/EditMSELTimeline';
import { HelpPreparePlan, useSelfHostedKnowledgeBase} from 'assets/data/HelpContent';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { toastConfig } from 'assets/data/config';
import {
  endLoading,
  startLoading,
} from '../../../../reducers/loading/loading.action';
import isRoleReadOnly from 'PREPAREsrc/utils/isRoleReadOnly';
import { useNewUIFlag } from '../../../../utils/featureFlags';
import classnames from 'classnames';
import PageTitle from '../../../../components/headers/PageTitle';
import { MissionTimelineDialog } from '../Missions/MissionTimeline/TimelineDetails/MissionTimelineDialog';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

const status = ['Draft', 'Scheduled', 'In-progress', 'Complete', 'Incomplete'];

export default function PlanDashboard() {
  const newUI = useNewUIFlag();
  const [meetings, setMeetings] = useState([]);
  const [exerciseDetails, setExcerciseDetails] = useState([]);
  const [refinedMeetings, setRefinedMeetings] = useState([]);
  const [mselList, setMselList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [editMSELTimeline, setEditMSELTimeline] = useState(false);
  const [viewId, setViewId] = useState();
  const [mission, setMission] = useState();
  const [showCreateMSELInject, setShowCreateMSELInject] = useState(false);
  const [showCreateParticipant, setShowCreateParticipant] = useState(false);
  const [
    showCreateTrainingObjectiveDialog,
    setShowCreateTrainingObjectiveDialog,
  ] = useState(false);

  const [eventDetails, setEventDetails] = useState();
  const [teEventDetails, setTEEventDetails] = useState();
  const [missionTimelines, setMissionTimelines] = useState([]);
  const [search, setSearch] = useState('');
  const [openMissionTimelineDialog, setOpenMissionTimelineDialog] = useState(
    false
  );
  const [openDialogIndex, setOpenDialogindex] = useState(0);
  const [allStatusLabel, setAllStatusLabel] = useState([]);
  const [allMissions, setAllMissions] = useState([]);
  const [limit, setLimit] = useState(10);
  const [loadedDisplay, setLoadedDisplay] = useState([]);
  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase();

  const navigate = useNavigate();

  const { selectedWorkspace, workspaceName } = useSelector(
    (state) => state.prepare.workspace
  );
  const workspaceType = useSelector((state) => state.prepare.workspace.type);

  const reduxUser = useSelector((state) => state.prepare.user);

  const dispatch = useDispatch();

  const fetchAllInject = async () => {
    if (selectedWorkspace) {
      try {
        const resp = await Network.get(API.fetchAllInject, {
          workspaceId: selectedWorkspace,
          search: searchTerm,
        });
        setMselList(resp.data.response.dataset);
      } catch (error) {
        toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
      }
    }
  };
  const fetchAllMissions = async () => {
    try {
      dispatch(startLoading());
      const response = await Network.get(API.fetchAllMissions, {
        workspaceId: selectedWorkspace,
      });
      if (response?.status === 200 && response !== undefined) {
        const allMissions = response.data.response.dataset.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        setAllMissions(allMissions);
        dispatch(endLoading());
      }
    } catch (error) {
      console.error(error);
      dispatch(endLoading());
    }
  };

  const getMissionTimelineData = async (missionId) => {
    try {
      dispatch(startLoading());
      const response = await Network.get(API.getMissionTimelineData, {
        workspaceId: selectedWorkspace,
        missionId,
      });
      if (response?.status === 200 && response !== undefined) {
        let data = response.data.response.dataset[0];
        const updatedData = data.mission_timeline.map((item) => ({
          ...item,
          mission_id: missionId,
        }));
        setMissionTimelines(updatedData);
        dispatch(endLoading());
      }
    } catch (error) {
      console.error(error);
      dispatch(endLoading());
    }
  };

  const allPlanStatusLabelList = async () => {
    try {
      const statusLabel = await Network.get(API.planStatusLabelList, {
        workspaceId: selectedWorkspace,
      });
      setAllStatusLabel(
        statusLabel.data.response.dataset
          .map((item) => ({
            value: {
              id: item.id,
              status: item.title,
              statusColor: item.color,
            },
            label: (
              <>
                <span
                  style={{
                    backgroundColor: item.color,
                    height: '20px',
                    width: '20px',
                    borderRadius: '4px',
                  }}
                  className="me-2"
                ></span>
                {item.title}
              </>
            ),
            name: item.title,
          }))
          .filter((item) => status.includes(item.name))
      );
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    }
  };

  const onViewInject = (type, id) => {
    if (type === 'exercise') {
      navigate(`${PreparePath}/dashboard/plan/prepare/exercise-details`, {
        replace: true,
      });
    } else if (type === 'event') {
      navigate(`${PreparePath}/dashboard/plan/prepare/event/view/${id}`, {
        replace: true,
      });
    } else if (type === 'meeting') {
      navigate(
        `${PreparePath}/dashboard/plan/prepare/exercise-details?tabKey=planning_meetings`,
        {
          replace: true,
        }
      );
    } else {
      setViewId(id);
      setEditMSELTimeline(true);
    }
  };

  const onCloseViewInjectModal = () => {
    setViewId();
    setEditMSELTimeline(false);
  };

  const planDashboard = async (optionalLimit) => {
    dispatch(startLoading());
    if (selectedWorkspace) {
      try {
        const resp = await Network.get(API.planDashhboard, {
          workspaceId: selectedWorkspace,
          startDate: moment().clone().startOf('month').toISOString(),
          endDate: moment().toISOString(),
          search,
          limit: optionalLimit || limit,
        });
        if (resp !== undefined && resp?.status === 200) {
          setMeetings(resp.data.response.calendarData);
          setExcerciseDetails({
            ...resp.data.response.details,
            orgCapTarget: resp.data.response.orgCapTarget || 0,
          });
          dispatch(selectedEventId(resp?.data?.response?.eventDetails?.id));
          if (workspaceType === 'T&E') {
            setEventDetails(resp?.data?.response?.eventDetails);
            setTEEventDetails(resp?.data?.response?.TEEventDetails);
            setLoadedDisplay(resp?.data?.response?.planMissionTimeline);
            setMissionTimelines(resp?.data?.response?.planMissionTimeline);
          }
        }
        dispatch(endLoading());
      } catch (error) {
        toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
        dispatch(endLoading());
      }
    }
  };

  useEffect(() => {
    planDashboard();
    allPlanStatusLabelList();
  }, [reduxUser, selectedWorkspace, search, workspaceType]);

  useEffect(() => {
    if (meetings) {
      const planCalen = meetings.map((e) => {
        const refinedPlanCalendar = {
          title: e.eventName,
          start: moment(e?.startDate).toISOString(),
          end: moment(e?.endDate).toISOString(),
          type: e.type,
          id: e.id,
          color: e?.color,
        };
        return refinedPlanCalendar;
      });
      setRefinedMeetings(planCalen);
    } else {
      setRefinedMeetings([]);
    }
  }, [meetings, selectedWorkspace]);

  useEffect(() => {
    fetchAllInject();
    fetchAllMissions();
  }, [reduxUser, searchTerm, selectedWorkspace]);

  const onChangeSearchTerm = (termSearched) => {
    setSearchTerm(termSearched);
  };

  const onCloseModalMSEL = () => {
    setShowCreateMSELInject(false);
  };

  const createHandler = () => {
    setShowCreateMSELInject(true);
  };

  const onAddParticipants = () => {
    setShowCreateParticipant(true);
  };

  const onCloseModalParticipant = () => {
    setShowCreateParticipant(false);
  };

  const createHandlerTrainingObjective = () => {
    setShowCreateTrainingObjectiveDialog(true);
  };

  const dialogCloseHandler = () => {
    setShowCreateTrainingObjectiveDialog(false);
  };

  return (
    <>
      {(workspaceType !== 'T&E' && (
        <div className="row">
          {newUI ? (
            <PageTitle
              title="Exercise Management Dashboard"
              parents={['Exercise Management']}
            >
              <Dropdown>
                <Dropdown.Toggle
                  className={`button button--primary button--create px-4 ${
                    isRoleReadOnly(reduxUser.roles) ? 'disabled' : ''
                  }`}
                >
                  <SharedIcon iconName="add" classes="px-0" bold />
                  <span>Create</span>
                  <SharedIcon
                    iconName="keyboard_arrow_down"
                    classes="px-0"
                    bold
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu align="start">
                  <Dropdown.Item onClick={createHandler}>
                    New Inject
                  </Dropdown.Item>
                  <Dropdown.Item onClick={onAddParticipants}>
                    New Participant
                  </Dropdown.Item>
                  <Dropdown.Item onClick={createHandlerTrainingObjective}>
                    New Objective
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <StylishHelp
                classes={'sml ms-3'}
                title={HelpPreparePlan.title}
                content={HelpPreparePlan.content}
                link={selfHostedKnowledgeBase || HelpPreparePlan.link}
              />
            </PageTitle>
          ) : (
            <div className="d-flex align-items-center mb-4">
              <Dropdown>
                <Dropdown.Toggle
                  className={`button button--primary button--create px-4 ${
                    isRoleReadOnly(reduxUser.roles) ? 'disabled' : ''
                  }`}
                >
                  <SharedIcon iconName="add" classes="px-0" bold />
                  <span>Create</span>
                  <SharedIcon
                    iconName="keyboard_arrow_down"
                    classes="px-0"
                    bold
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu align="start">
                  <Dropdown.Item onClick={createHandler}>
                    New Inject
                  </Dropdown.Item>
                  <Dropdown.Item onClick={onAddParticipants}>
                    New Participant
                  </Dropdown.Item>
                  <Dropdown.Item onClick={createHandlerTrainingObjective}>
                    New Objective
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <StylishHelp
                classes={'ms-auto'}
                title={HelpPreparePlan.title}
                content={HelpPreparePlan.content}
                link={selfHostedKnowledgeBase || HelpPreparePlan.link}
              />
            </div>
          )}
          <div className="col-12">
            <div className="row h-lg-100">
              <div className="col-lg-6 h-lg-100 mb-4 mb-lg-0">
                <div className="section section__exercise-details with-separator--bottom pb-4">
                  <div className="d-flex align-items-center mb-4">
                    <h4 className="m-0">Exercise Details</h4>
                    <Dropdown className="flex-shrink-0 ms-auto">
                      <Dropdown.Toggle
                        className={`button--icon ${
                          isRoleReadOnly(reduxUser.roles) ? 'disabled' : ''
                        }`}
                      >
                        <SharedIcon iconName="more_vert" bold />
                      </Dropdown.Toggle>
                      <Dropdown.Menu align="end">
                        <Dropdown.Item
                          onClick={() => {
                            navigate(
                              `${PreparePath}/dashboard/plan/prepare/all-details`
                            );
                          }}
                        >
                          <SharedIcon iconName="stylus" />
                          Edit Exercise Details
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  {exerciseDetails.startDate ? (
                    <>
                      {workspaceType === 'DOD' ? (
                        <>
                          <p className="weight-600">
                            <span className="d-block">
                              {workspaceName || exerciseDetails.eventName}-
                              {exerciseDetails.exerciseType}
                            </span>
                            {!!exerciseDetails?.startDate &&
                              moment(exerciseDetails.startDate).format(
                                'DD MMM YY'
                              )}
                            -{' '}
                            {!!exerciseDetails?.endDate &&
                              moment(exerciseDetails.endDate).format(
                                'DD MMM YY'
                              )}
                          </p>
                          <span className="d-block">
                            {exerciseDetails.total_exercise_objectives} -
                            Exercise Objectives
                          </span>
                          <span className="d-block">
                            {exerciseDetails.total_participants} - Participants
                            with {exerciseDetails.total_participant_platforms}{' '}
                            Platforms
                          </span>
                          <span className="d-block">
                            {exerciseDetails.total_training_objectives} -
                            Objectives
                          </span>
                          <span className="d-block">
                            {exerciseDetails.total_events} - Scenarios
                          </span>
                          <span className="d-block">
                            {exerciseDetails.total_injects} - MSEL Injects
                          </span>
                        </>
                      ) : (
                        <>
                          <p className="weight-600">
                            <span className="d-block">
                              {workspaceName || exerciseDetails.eventName}-
                              {exerciseDetails.exerciseType}
                            </span>
                            {!!exerciseDetails?.startDate &&
                              moment(exerciseDetails.startDate).format(
                                'DD MMM YY'
                              )}
                            -{' '}
                            {!!exerciseDetails?.endDate &&
                              moment(exerciseDetails.endDate).format(
                                'DD MMM YY'
                              )}
                          </p>
                          <span className="d-block">
                            {exerciseDetails.orgCapTarget} - Organizational
                            Capability Targets
                          </span>
                          <span className="d-block">
                            {exerciseDetails.total_participants} - Participants
                            with {exerciseDetails.total_participant_platforms}{' '}
                            Platforms
                          </span>
                          <span className="d-block">
                            {exerciseDetails.total_training_objectives} -
                            Exercise Objectives
                          </span>
                          <span className="d-block">
                            {exerciseDetails.total_events} - Scenarios
                          </span>
                          <span className="d-block">
                            {exerciseDetails.total_injects} - MSEL Injects
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <div className="placeholders">
                      <p>
                        No exercise details found. Start planning <br />
                        by entering them now.
                      </p>
                      <StylishNewButton
                        className="button button--primary"
                        onClick={() => {
                          navigate(
                            `${PreparePath}/dashboard/plan/prepare/all-details`
                          );
                        }}
                      >
                        Enter Details
                      </StylishNewButton>
                    </div>
                  )}
                </div>

                <div className="section section__msel-list pt-4">
                  <div className="d-flex align-items-center mb-4">
                    <h4 className="m-0">MSEL List</h4>
                    <Dropdown className="flex-shrink-0 ms-auto">
                      <Dropdown.Toggle
                        className={`button--icon ${
                          isRoleReadOnly(reduxUser.roles) ? 'disabled' : ''
                        }`}
                      >
                        <SharedIcon iconName="more_vert" bold />
                      </Dropdown.Toggle>
                      <Dropdown.Menu align="end">
                        <Dropdown.Item
                          onClick={() => {
                            navigate(
                              `${PreparePath}/dashboard/plan/prepare/msel-list`
                            );
                          }}
                        >
                          <SharedIcon iconName="stylus" />
                          Edit MSEL
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="mb-3">
                    <StylishNewSearchBar
                      onChangeSearchTerm={onChangeSearchTerm}
                    />
                  </div>
                  {mselList.length ? (
                    <div className="list__msel">
                      {mselList.map((e, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            if (!isRoleReadOnly(reduxUser.roles)) {
                              onViewInject(e.type, e.id);
                            }
                          }}
                          className={classnames(
                            'bg-gray-800 rounded p-3 txt--sml',
                            {
                              'cursor-pointer': !isRoleReadOnly(
                                reduxUser.roles
                              ),
                              'mt-2': index > 0,
                            }
                          )}
                        >
                          #{e.number}{' '}
                          {e.statusLabel ? (
                            <span
                              className="status mx-1"
                              style={{ backgroundColor: e.statusColor }}
                            >
                              {e.statusLabel}
                            </span>
                          ) : null}
                          {e.injectTitle}{' '}
                          {!!e.startDate &&
                            moment(e.startDate).format('DD MMM YY ')}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="list__msel">
                      <div className="placeholders h-100 py-5">
                        <p>
                          No MSEL Injects found. Start planning <br />
                          by entering them now.
                        </p>
                        <StylishNewButton
                          className="button button--primary"
                          onClick={() => {
                            if (!isRoleReadOnly(reduxUser.roles)) {
                              navigate(
                                `${PreparePath}/dashboard/plan/prepare/msel-list`
                              );
                            }
                          }}
                        >
                          Create MSEL Inject
                        </StylishNewButton>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-6 h-lg-100">
                <div className="section section__calendar">
                  <div className="d-flex align-items-center mb-4">
                    <h4 className="m-0">Calendar</h4>
                  </div>
                  {refinedMeetings.length ? (
                    <div className="calendar calendar-dashboard">
                      <FullCalendar
                        validRange={''}
                        plugins={[listPlugin]}
                        headerToolbar={{
                          left: 'title',
                          center: '',
                          right: 'prev,next today',
                        }}
                        initialView="listMonth"
                        events={refinedMeetings}
                        eventClick={(info) => {
                          if (!isRoleReadOnly(reduxUser.roles)) {
                            onViewInject(
                              info.event._def?.extendedProps?.type,
                              info.event._def?.publicId
                            );
                          }
                        }}
                        eventTimeFormat={{
                          hour: '2-digit',
                          minute: '2-digit',
                          meridiem: 'short',
                          hour12: reduxUser?.timeFormat === 'hour12Mode',
                        }}
                        displayEventTime={true}
                      />
                    </div>
                  ) : (
                    <div className="placeholders">
                      <p className="m-0 p-4 weight-600">No events to display</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )) || (
        <div className="workspace__te">
          <div className="row">
            <div className="col-md-6 col-xl-8">
              <div className="row">
                <div className="col-xl-6 block__event">
                  <h4>Experimentation Event Details</h4>
                  {(eventDetails && teEventDetails && (
                    <>
                      {(eventDetails?.name && (
                        <h6 className="mb-1">{eventDetails?.name}</h6>
                      )) ||
                        null}
                      {(eventDetails?.startDate && eventDetails?.endDate && (
                        <h6 className="m-0">
                          {moment(eventDetails?.startDate).format(
                            'DD MMM YY'
                          ) || ''}
                          {` - ${
                            moment(eventDetails?.endDate).format('DD MMM YY') ||
                            ''
                          }`}
                        </h6>
                      )) ||
                        null}
                      <ul className="list square mt-4">
                        <li>{teEventDetails?.total_coic || 0} - COICs</li>
                        <li>{teEventDetails?.total_moe || 0} - MOEs</li>
                        <li>{teEventDetails?.total_mos || 0} - MOSs</li>
                        <li>{teEventDetails?.total_mop || 0} - MOPs</li>
                        <li>{teEventDetails?.total_system || 0} - Systems</li>
                        <li>
                          {teEventDetails?.total_components || 0} - Components
                        </li>
                        <li>{teEventDetails?.total_mission || 0} - Missions</li>
                        {/* <li>32 - Sorties</li>
                        <li>12 - Assessments</li>
                        <li>20 - Experimentations</li> */}
                      </ul>
                    </>
                  )) || (
                    <div className="bg-gray-800 p-5 d-flex align-items-center">
                      <div className="d-flex flex-column align-items-center flex-grow-1">
                        <i
                          className="fa fa-exclamation-triangle color-muted fa-3x"
                          aria-hidden="true"
                        ></i>
                        <p className="weight-500 mt-3 mb-0 text-center">
                          No experimentation event details found
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-xl-6 block__mission">
                  <h4>Mission Timeline</h4>
                  <span className="mb-3">
                    <StylishNewSearchBar onChangeSearchTerm={setSearch} />
                    <div className="d-flex align-items-center">
                      <StylishNewSelect
                        options={allMissions}
                        onChange={(e) => {
                          if (e?.value) {
                            getMissionTimelineData(e.value);
                          } else {
                            setMissionTimelines(loadedDisplay);
                            setLimit(10);
                          }
                          setMission(e?.label);
                        }}
                        isSearchable={false}
                        isClearable={true}
                        isMulti={false}
                        placeholder={mission || 'Select Mission'}
                        classes="flex-grow-1 me-2 mt-2"
                      />
                      <StylishNewSelect
                        value={limit}
                        options={[
                          { label: 10, value: 10 },
                          { label: 25, value: 25 },
                          { label: 50, value: 50 },
                          { label: 100, value: 100 },
                        ]}
                        onChange={(e) => {
                          setLimit(e?.value);
                          if (mission) {
                            getMissionTimelineData(mission);
                          } else {
                            planDashboard(e?.value);
                          }
                        }}
                        isSearchable={false}
                        isMulti={false}
                        placeholder={limit || 'Display'}
                        className="me-2 mt-2"
                      />
                    </div>
                  </span>
                  {(missionTimelines && !!missionTimelines?.length && (
                    <>
                      <div className="flex-grow-1 scroll">
                        {missionTimelines?.slice(0, limit).map((item, idx) => (
                          <div
                            className={`bg-gray-800 rounded p-3 txt--sml cursor-pointer d-flex align-items-center ${
                              idx !== 0 ? 'mt-2' : ''
                            }`}
                            key={item?.number || idx + 1}
                            onClick={() => {
                              setOpenDialogindex(idx);
                              setOpenMissionTimelineDialog(true);
                            }}
                          >
                            #{item?.number || idx + 1}
                            <span
                              className="status mx-2"
                              style={{ background: item?.status_color }}
                            >
                              {item?.status}
                            </span>
                            <p className="m-0">
                              <span className="d-block">
                                {moment(
                                  item?.mission_timeline_start_date
                                ).format('DD MMM YY @ hh:mm a')}
                              </span>
                              <span className="d-block mt-1">
                                {item?.title || item?.mission_timeline_name}
                              </span>
                            </p>
                          </div>
                        ))}
                      </div>
                    </>
                  )) || (
                    <div className="bg-gray-800 p-5 d-flex align-items-center">
                      <div className="d-flex flex-column align-items-center flex-grow-1">
                        <i
                          className="fa fa-exclamation-triangle color-muted fa-3x"
                          aria-hidden="true"
                        ></i>
                        <p className="weight-500 mt-3 mb-0 text-center">
                          No mission timeline found
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 block__calendar">
              <h4>Calendar</h4>
              {(refinedMeetings && !!refinedMeetings?.length && (
                <div className="calendar calendar-dashboard">
                  <FullCalendar
                    validRange={''}
                    plugins={[listPlugin]}
                    headerToolbar={{
                      left: 'title',
                      center: '',
                      right: 'prev,next',
                    }}
                    initialView="listMonth"
                    events={refinedMeetings}
                    eventTimeFormat={{
                      hour: '2-digit',
                      minute: '2-digit',
                      meridiem: 'short',
                      hour12: reduxUser?.timeFormat === 'hour12Mode',
                    }}
                    displayEventTime={true}
                  />
                </div>
              )) || (
                <div className="bg-gray-800 p-5 d-flex align-items-center">
                  <div className="d-flex flex-column align-items-center flex-grow-1">
                    <i
                      className="fa fa-exclamation-triangle color-muted fa-3x"
                      aria-hidden="true"
                    ></i>
                    <p className="weight-500 mt-3 mb-0 text-center">
                      No event found
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {editMSELTimeline && (
        <EditMSELTimeline
          show={editMSELTimeline}
          onClose={onCloseViewInjectModal}
          viewId={viewId}
          onStatusUpdate={fetchAllInject}
        />
      )}
      {showCreateMSELInject && (
        <MSELCreate show={showCreateMSELInject} onClose={onCloseModalMSEL} />
      )}
      {showCreateParticipant && (
        <CreateParticipant
          show={showCreateParticipant}
          onClose={onCloseModalParticipant}
        />
      )}
      {showCreateTrainingObjectiveDialog && (
        <TrainingObjectiveCreateDialog
          show={showCreateTrainingObjectiveDialog}
          onClose={dialogCloseHandler}
        />
      )}
      {openMissionTimelineDialog && (
        <MissionTimelineDialog
          show={openMissionTimelineDialog}
          onClose={() => setOpenMissionTimelineDialog(false)}
          missionTimelineId={
            missionTimelines[openDialogIndex]?.mission_timeline_id
          }
          missionId={missionTimelines[openDialogIndex]?.mission_id}
          statusLabel={allStatusLabel}
          updateMissionTimeList={planDashboard}
        />
      )}
    </>
  );
}
