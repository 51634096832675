import * as turf from '@turf/turf';

export default function queryBingNewsEffect(
  selectedEvent,
  selectedEventTabNews,
  setSelectedEventTabNews,
  eventLocationInfo,
  bingNewsKey,
  key,
  selectedDatetime
) {

  if (!!selectedEvent && key === 'news' && ((!!eventLocationInfo && !!selectedEvent?.bounds) || selectedEvent.hazard_name==="Hurricane")) {

    let centroid = eventLocationInfo?.centroid;
    let BING_ENDPOINT = 'https://api.bing.microsoft.com/v7.0/news/search';
    let headers = {
      'Ocp-Apim-Subscription-Key': bingNewsKey,
    };

    // Format selectedDatetime to match API requirements (e.g., 'yyyy-MM-dd')
    const formattedDate = new Date(selectedDatetime).toISOString().split('T')[0];

    // Create the date range for the query (start of the day to end of the day)
    const dateRange = `${formattedDate}..${formattedDate}`;

    if (selectedEvent.hazard_name === "Hurricane") {
      BING_ENDPOINT += `?q=${selectedEvent.hurricane_data_points[0].storm_type} ${selectedEvent.hurricane_data_points[0].unified_storm_name}`;
    } else {
      BING_ENDPOINT =
        BING_ENDPOINT +
        '?q=' +
        selectedEvent.hazard_name +
        ' ' +
        eventLocationInfo.placeName +
        '&cc=' +
        eventLocationInfo.cc;
      if(!!centroid)
      {
        headers['X-Search-Location'] = `lat:${centroid.geometry.coordinates[1]};long:${centroid.geometry.coordinates[0]};re:2000m`;
      }
    }

    // Add the date range to the query string
    BING_ENDPOINT += `&date=${dateRange}&sortBy=Date&count=100`;

    fetch(BING_ENDPOINT, {
      headers: headers,
    })
      .then((res) => res.json())
      .then((response) => {
        const filteredArticles = response.value
          .filter((article) => {
            const articleDate = new Date(article.datePublished);
            return articleDate <= new Date(selectedDatetime);
          })
          .sort((a, b) => {
            const dateA = new Date(a.datePublished);
            const dateB = new Date(b.datePublished);
            return dateB - dateA;
          });

        setSelectedEventTabNews(filteredArticles);
      })
      .catch((error) => {
        console.error('Bing News API ERROR', error);
      });
  }
}
