import { useFormContext } from 'react-hook-form';
import React, { useEffect, useRef } from 'react';
import { StylishNewButton } from '../../DesignSystems/New/StylishNewButton';
import {
  selectGroupGuid,
  useAppSelector,
} from '../../../slices/commonSelectors';
import API from '../../../PREPAREsrc/service/api';
import Network from '../../../PREPAREsrc/service/Network';
import ExpandDownArrow from '../../DesignSystems/buttons/ExpandDownArrow';
import ExpandRightArrow from '../../DesignSystems/buttons/ExpandRightArrow';
import { useToggle } from 'react-use';
import Collapse from '@mui/material/Collapse';
import fileDownload from 'js-file-download';

type FormFileFieldProps = {
  title: string;
  fieldName: string;
  rules?: { [key: string]: { value: any; message: string } };
};

// TODO: use some form validation so the errors are displayed
const FormFileField: React.FC<FormFileFieldProps> = ({
  title,
  fieldName,
  rules,
}) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [signedUrl, setSignedUrl] = React.useState<string | null>(null);
  const [expanded, toggleExpanded] = useToggle(false);
  const fileRef = useRef<HTMLInputElement>(null);

  const groupGuid = useAppSelector(selectGroupGuid);

  const formFileUrl = watch(fieldName);

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      console.error('Tried to upload a file but no file was selected');
      return;
    }

    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    formData.append('group_guid', groupGuid);
    try {
      const resp = await Network.post(API.singleFileUpload, formData);
      const fileUrl = resp.data.response.dataset[0].fileUrl;
      setValue(fieldName, fileUrl);
    } catch (error) {
      console.error(error);
    } finally {
      setSignedUrl(null);
    }
  };

  const download = async () => {
    if (!signedUrl) {
      return;
    }

    const res = await fetch(signedUrl, {
      headers: {},
    });

    // url: server/container/294920234824-<filename>
    const filename = formFileUrl.split('/')[formFileUrl.split('/').length - 1];
    const dashIdx = filename.indexOf('-');
    const cleanedFilename = filename.substring(dashIdx + 1);

    fileDownload(
      await res.blob(),
      cleanedFilename
    );
  }

  useEffect(() => {
    if (expanded && !signedUrl) {
      Network.post(API.generateSignedUrl, {
        url: formFileUrl,
      }).then((res) => {
        setSignedUrl(res.data.response.singedUrl);
      });
    }
  }, [expanded, formFileUrl]);

  const required = typeof rules?.required === 'object';

  return (
    <>
      <div className={''}>
        <div className="d-flex align-items-center mb-2">
          <div className={'flex-grow-1 d-flex align-items-center'}>
            {formFileUrl ? (
              expanded ? (
                <ExpandDownArrow onClick={toggleExpanded} />
              ) : (
                <ExpandRightArrow onClick={toggleExpanded} />
              )
            ) : (
              <div style={{marginLeft: '1.5rem'}}></div>
            )}

            <div className="me-2">
              {title}
              {required ? (
                <span aria-label="Required field" className="required">
                  *
                </span>
              ) : null}
            </div>
          </div>
          <div>
            <StylishNewButton
              className="button--link"
              onClick={() => {
                if (fileRef.current) {
                  fileRef.current.click();
                }
              }}
            >
              Upload New
            </StylishNewButton>
          </div>
        </div>
        <Collapse in={expanded}>
          {errors[fieldName] ? (
            <div className="form-text form-error">
              {errors[fieldName].message as string}
            </div>
          ) : (
            <div className="form-text-none"></div>
          )}
          <StylishNewButton className="button--link" onClick={download}>Download</StylishNewButton>
          {signedUrl ? (
            <img src={signedUrl} alt="file" style={{ maxWidth: '40rem' }} />
          ) : (
            <div
              style={{ width: '40rem', height: '20rem' }}
              className={'d-flex justify-content-between align-items-center'}
            >
              <div>Loading Image...</div>
            </div>
          )}
        </Collapse>
      </div>
      <input
        type="file"
        className="d-none"
        onChange={onFileChange}
        ref={fileRef}
      />
    </>
  );
};
export default FormFileField;
