import { fetchTeamTemplates, generateWorkspace } from 'actions/teamsActions';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { startLoading } from 'reducers/loading/loading.action';

interface Props {
  show: boolean;
  onClose: () => void;
  updateIsWorkspaceCreated: () => void;
}

export const ExerciseWorkspaceDialog = ({
  show,
  onClose,
  updateIsWorkspaceCreated,
}: Props) => {
  const dispatch = useDispatch();

  const [selectedTemplate, setSelectedTemplate] = useState('');

  const tenantId = useSelector((state: any) => state.app.tenantId);
  const teamsTemplates = useSelector((state: any) => state.app.teamsTemplates);
  const { selectedWorkspace } = useSelector(
    (state: any) => state.prepare.workspace
  );

  useEffect(() => {
    if (teamsTemplates.length === 0) {
      dispatch(startLoading());
      dispatch(fetchTeamTemplates(tenantId));
    }
  }, [teamsTemplates, tenantId]);

  const onSubmit = (param: any) => {
    dispatch(generateWorkspace(selectedWorkspace, param, tenantId));
    onClose();
    updateIsWorkspaceCreated();
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Choose Teams Template and Exercise</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <label className="form-label">Teams Template</label>
          <StylishNewSelect
            options={teamsTemplates.map((template: any) => ({
              value: template.value,
              label: template.label,
            }))}
            value={selectedTemplate || ''}
            onChange={(e) => {
              setSelectedTemplate(e);
            }}
            placeholder={'Please Select a Template'}
            isClearable={false}
            isSearchable={true}
            isMulti={false}
            isDisabled={false}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className="button--secondary button--reverse"
            type="button"
            onClick={onClose}
          >
            Close
          </StylishNewButton>
          <StylishNewButton
            disabled={selectedTemplate === ''}
            type="submit"
            className="button--primary"
            onClick={() => onSubmit(selectedTemplate)}
          >
            Save
          </StylishNewButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
